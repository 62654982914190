import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/SuggestionsPage.css';

const SuggestionsPage = () => {
    const [suggestions, setSuggestions] = useState(null);

    useEffect(() => {
        const fetchSuggestions = async () => {
            const url = 'https://notes.petieclark.com/api/documents.info';
            const body = {
                id: '53e36d39-430f-45bc-9d67-986ccf124b62'
            };
            const headers = {
                'Authorization': 'Bearer ol_api_lrpXW7PkT13oLGMZPT1p3u1EMO38slZcfzgfKH',
                'Content-Type': 'application/json'
            };

            try {
                const response = await axios.post(url, body, { headers });
                console.log('Suggestions response:', response.data);
                setSuggestions(response.data.data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, []);

    if (!suggestions) {
        return <div>Loading...</div>;
    }

    return (
        <div className="suggestions-container">
            <h2>{suggestions.title}</h2>
            <p>{suggestions.text}</p>
        </div>
    );
};

export default SuggestionsPage;