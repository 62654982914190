import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import SuggestionsPage from './pages/SuggestionsPage';
import StudyPage from './pages/StudyPage';
import StudyGuidePage from './pages/StudyGuidePage';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>GroupHub</h1>
                    <nav>
                        <ul>
                            <li><Link to="/signup">Food Sign-Up</Link></li>
                            <li><Link to="/suggestions">Food Suggestions for This Week</Link></li>
                            <li><Link to="/study">Study Guides</Link></li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <Routes>
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/suggestions" element={<SuggestionsPage />} />
                        <Route path="/study" element={<StudyPage />} />
                        <Route path="/study/:id" element={<StudyGuidePage />} />
                        <Route path="/" element={<SignUpPage />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;