import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/SignUpPage.css'; // Ensure this path is correct

const SignUpPage = () => {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    const [name, setName] = useState('');

    const fetchItems = async () => {
        const url = 'https://grist.petieclark.com/api/docs/nKMoxtK1SHsiVad2YFUBAM/tables/GroupFoodList/records';
        const headers = {
            'Authorization': 'Bearer e84e4d8fa4fd37d212d3bf47a8f9f91b31ddafda',
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(url, { headers });
            console.log('Grist table response:', response.data);
            const records = response.data.records.map(record => ({
                name: record.fields.Who,
                bringing: record.fields.What
            }));
            setItems(records);
        } catch (error) {
            console.error('Error fetching Grist table:', error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const handleSignUp = async () => {
        const url = 'https://workflows.petieclark.com/api/w/homelab/jobs/run/f/u/petie/rational_flow?token=sSur0Pog5YWEXX5ICUSWNnZSmnbiC6h3';
        const payload = {
            bringing: newItem,
            name: name
        };

        try {
            await axios.post(url, payload);
            fetchItems();
            setNewItem('');
            setName('');
        } catch (error) {
            console.error('Error posting to workflow:', error);
        }
    };

    return (
        <div className="sign-up-container">
            <h2>Sign Up for Food</h2>
            <input 
                type="text" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                placeholder="Your Name" 
            />
            <input 
                type="text" 
                value={newItem} 
                onChange={(e) => setNewItem(e.target.value)} 
                placeholder="What will you bring?" 
            />
            <button onClick={handleSignUp}>Sign Up</button>
            <h3>Who is Bringing What</h3>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <span>{item.name}</span>
                        <span>{item.bringing}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SignUpPage;