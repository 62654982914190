import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import '../css/StudyGuidePage.css';

const StudyGuidePage = () => {
    const { id } = useParams();
    const [studyGuide, setStudyGuide] = useState(null);

    useEffect(() => {
        const fetchStudyGuide = async () => {
            const url = 'https://notes.petieclark.com/api/documents.info';
            const body = {
                id: id
            };
            const headers = {
                'Authorization': 'Bearer ol_api_lrpXW7PkT13oLGMZPT1p3u1EMO38slZcfzgfKH',
                'Content-Type': 'application/json'
            };

            try {
                const response = await axios.post(url, body, { headers });
                console.log('StudyGuide response:', response.data);
                setStudyGuide(response.data.data);
            } catch (error) {
                console.error('Error fetching study guide:', error);
            }
        };

        fetchStudyGuide();
    }, [id]);

    if (!studyGuide) {
        return <div>Loading...</div>;
    }

    return (
        <div className="study-guide-container">
            <h2>{studyGuide.title}</h2>
            <ReactMarkdown>{studyGuide.text}</ReactMarkdown>
        </div>
    );
};

export default StudyGuidePage;